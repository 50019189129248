$small: 420px;
$medium: 900px;

$primary-color: #023e73;
$secondary-color: #ff8a4c;
$tertiary-color: #211e4f;
$header-color: #1d1a4e;
$text-color: #47465e;
$accent-color: #fff;
$secondary-light-color: #f8cbb4;
$text-light-color: #c5c5cd;

div.ant-select-selector {
    background-color: #f0f1f4 !important;
    border-color: $tertiary-color !important;
    border-radius: 0 !important;
    border-style: solid !important;
    border-width: 0 0 1px 0 !important;
    min-height: 35px;
}

div.ant-select.ant-select-status-error div.ant-select-selector {
    border-color: red !important;
    border-width: 1px !important;
}

input.ant-input {
    background-color: #f0f1f4 !important;
    border-color: $tertiary-color !important;
    border-radius: 0 !important;
    border-style: solid !important;
    border-width: 0 0 1px 0 !important;
    min-height: 39.5px;
}

input.ant-input.ant-input-status-error {
    border-color: red !important;
    border-width: 1px !important;
}

div.ant-picker {
    background-color: #f0f1f4 !important;
    border-color: $tertiary-color !important;
    border-radius: 0 !important;
    border-style: solid !important;
    border-width: 0 0 1px 0 !important;
    min-height: 39.5px;
}

div.ant-picker.ant-picker-status-error {
    border-color: red !important;
    border-width: 1px !important;
}

span.ant-checkbox-inner {
    background-color: #f3f4f6 !important;
    border-radius: 0 !important;
    border-width: 0 !important;
}

.ant-checkbox-wrapper-checked span.ant-checkbox-inner {
    background-color: $secondary-color !important;
}
