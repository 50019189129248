@import './theme.scss';

// regular texts and titles

body {
    font-family: 'Inter', sans-serif;
}

.text {
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    color: $text-color;
    font-weight: normal;
    line-height: inherit;
}

.bold,
.bold.ant-typography {
    font-weight: bold;
}

.label {
    font-weight: bold;
    line-height: 1;
    margin-bottom: 20px;
}

.title {
    font-size: 24px !important;
    font-family: 'Inter', sans-serif;
    color: $header-color !important;
    font-weight: bold;
    margin: 0 auto; //  evtl rausschmeißen
    text-align: center;
    margin-bottom: 30px;
}

.bigger {
    font-size: 40px !important;
    text-align: left;
}

.thin {
    font-weight: 200;
    line-height: normal;
}

.flex-centered {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.centered,
.text-centered {
    text-align: center;
}

.left {
    text-align: left;
}

.checked::before {
    content: '✓';
    color: green;
    display: inline-block;
    width: 17px;
    font-size: 16px;
    font-weight: bold;
}

// buttons

.primary-button,
.secondary-button {
    border-radius: 0;
    height: auto;
    padding: 14px 48px;
    padding-top: 12px;
    width: 100%;
    font-size: 18px;
    outline-color: rgba($secondary-color, 0.3) !important;
}

.primary-button:enabled {
    background-color: $secondary-color !important;
}

.secondary-button:enabled {
    color: $text-color !important;
    border-color: $text-color !important;
}

.primary-button:enabled:hover,
.secondary-button:enabled:hover {
    opacity: 0.7;
}

.display-circle {
    background-color: #f4f5f8;
    border-radius: 50%;
    display: grid;
    width: 192px;
    height: 192px;
    margin: auto;
    grid-template-rows: 1.25fr 1fr 1.25fr;
}

.display-circle2 {
    background-color: #f4f5f8;
    border-radius: 50%;
    display: grid;
    width: 128px;
    height: 128px;
    margin: auto;
    margin-right: -2.5rem;
    margin-top: -2rem;
    grid-template-rows: 1.25fr 1fr 1fr 1.25fr;
}

// page margins

$row-margin-phone: 20px;
$row-margin-desktop: 40px;
$left-margin: 10%;
$tablet-width: 601px;

.info-card {
    background-color: #f3f4f6;
    box-shadow: 0px 0px 10px;
    margin-bottom: 30px;
}

.bordered {
    border-color: lightgray;
    border-radius: 8px;
}

.contentTile {
    margin-bottom: $row-margin-phone;
}

.field {
    width: 100%;
    text-align: left;
}

.inner-field {
    width: 100%;
}

.second-column {
    margin-top: 16px;
}

.row {
    display: block;
    margin-bottom: 16px;
}

@media screen and (min-width: $tablet-width) {
    .title {
        margin-bottom: 50px;
    }

    .bigger {
        font-size: 50px !important;
    }

    .contentTile {
        margin-left: $left-margin;
        margin-right: $left-margin;
    }

    .field {
        width: 80%;
    }

    .row {
        display: flex;
    }

    .primary-button,
    .secondary-button {
        width: auto;
    }

    .display-circle2 {
        width: 192px;
        height: 192px;
        margin: auto;
        grid-template-rows: 1.25fr 1fr 1fr 1.25fr;
    }
}

@media screen and (min-width: $medium) {
    .no-space-line {
        margin-bottom: -18.5px;
    }

    .contentTile {
        margin-bottom: $row-margin-desktop;
    }

    .content {
        margin-left: $left-margin;
        margin-right: $left-margin;
    }
}

@media screen and (min-width: 1280px) {
    .field {
        width: 50%;
    }
}

@media screen and (min-width: 1920px) {
    .field {
        width: 30%;
    }
}

@media screen and (min-width: 3840px) {
    .field {
        width: 20%;
    }
}
