@import '../../styles/theme.scss';

.summary-card {
    border-color: $tertiary-color;
    border-radius: 4px;
    border-style: solid;
    border-width: 3px;
    margin-bottom: 48px;
    padding: 24px 48px;
}

.summary-card-header {
    background-color: white;
    color: $tertiary-color;
    font-size: 1.2rem;
    left: -18px;
    padding: 0 18px;
    position: relative;
    top: -36px;
}
