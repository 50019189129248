@import '../../styles/theme.scss';

.tarif-card {
    border-radius: 12px;
    border-width: 0;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    color: $tertiary-color;
    cursor: pointer;
    font-weight: 700;
    margin: 3px;
    min-width: 224px;
    text-align: center;
}

.tarif-card-selected {
    background-color: $tertiary-color;
    box-shadow: 0 0 2px;
    color: $accent-color;
    cursor: default;
    font-weight: 700;
    margin: 3px;
    min-width: 224px;
    text-align: center;
}

.tarif-card-selected .ant-typography {
    color: $accent-color;
}

.tarif-card-disabled {
    background-color: #f6f6f8;
    box-shadow: 0 0 2px;
    color: $text-light-color;
    cursor: default;
    margin: 3px;
}

.tarif-card-disabled .ant-typography {
    color: $text-light-color;
}
