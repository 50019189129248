@import '../../styles/theme.scss';

.summary-view,
.summary-view * {
    color: $tertiary-color !important;
}

.rate-card-content {
    display: grid;
    grid-template-columns: 3fr 1fr;
}

.rate-card-content-items {
    margin-bottom: 32px;
}

.summary-card-divider {
    border-color: rgba($color: $tertiary-color, $alpha: 0.4);
    margin: 16px 0;
}
