@import '../../styles/theme.scss';

.ant-checkbox-wrapper.required-checkbox > .ant-checkbox > span.ant-checkbox-inner {
    border-radius: 0 !important;
    border-width: 1px !important;
    border-color: red !important;
}

.ant-checkbox-wrapper-checked.required-checkbox > .ant-checkbox > span.ant-checkbox-inner {
    background-color: $secondary-color !important;
    border-width: 0 !important;
}
