@import '../../styles/theme.scss';

.ant-checkbox-wrapper.questions-view-inner-checkbox span.ant-checkbox-inner {
    background-color: white !important;
}

.ant-checkbox-wrapper-checked.questions-view-inner-checkbox span.ant-checkbox-inner {
    background-color: $secondary-color !important;
}

.question-outside-checkbox {
    margin-left: 8px;
}

@media screen and (min-width: 481px) {
    .question-outside-checkbox {
        margin-left: 26px;
    }
}
